<template>
  <v-container>
    <gantt-elastic
      :tasks="tasks"
      :options="options"
      :dynamic-style="dynamicStyle"
    >
      <gantt-elastic-header slot="header"></gantt-elastic-header>
      <!-- <gantt-elastic-footer slot="footer"></gantt-elastic-footer> -->
    </gantt-elastic>

    <template #top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Task - {{ currentHeading }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="myDialog" max-width="500px">
          <template #activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Ny</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <dlgTask
                    v-if="myDialog"
                    v-model="editedItem"
                    :keyProsjektID="keyProsjektID"
                    :TaskList="dataList"
                  />
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Avbryt</v-btn>
              <v-btn color="blue darken-1" text @click="save">Lagre</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";
import GanttElastic from "gantt-elastic";
import dayjs from "dayjs/locale/es";
import Header from "gantt-elastic-header"; // if you want standard header (npm i -s gantt-elastic-header)

export default {
  extends: KeyValue,
  //  el: "#gantt",
  components: {
    dlgTask: () => import("../dialog/dlgTask"),
    ganttElasticHeader: Header,
    ganttElastic: GanttElastic,
    //ganttElasticFooter: {template:`<span>your footer</span>`},
  },
  data() {
    return {
      ganttInstance: "",
      keyProsjektID: "",
      currentTaskID: "",
      myDialog: false,
      dataList: [],
      editedIndex: -1,
      editedItem: {
        TaskID: "",
        ProsjektID: "",
        ParentTaskID: "",
        TaskStatusID: "",
        TaskTypeID: "",
        BrukerID: "",
        Task: "",
        ProsentFerdig: "",
        Tidsfrist: "",
        Start: "",
        Slutt: "",
        Kommentar: "",
        EstimertTid: "",
        EstimertKost: "",
        itemsAvhengighet: [],
        itemsSakTask: [],
        itemsTaskVedlegg: [],
      },
      defaultItem: {
        TaskID: "",
        ProsjektID: "",
        ParentTaskID: "",
        TaskStatusID: "1",
        TaskTypeID: "1",
        BrukerID: "",
        Task: "",
        ProsentFerdig: "0",
        Tidsfrist: "",
        Start: "",
        Slutt: "",
        Kommentar: "",
        EstimertTid: "0",
        EstimertKost: "0",
      },

      //tasks: tasks,
      //options: options
      dynamicStyle: {
        "task-list-header-label": {
          "font-weight": "bold",
        },
      },
      //tasks: [],
      tasks: [
        {
          id: 1,
          label: "Make some noise",
          user:
            '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
          start: this.getDate(-24 * 5),
          duration: 15 * 24 * 60 * 60 * 1000,
          progress: 85,
          type: "project",
          //collapsed: true,
        },
        {
          id: 20,
          label: "With great power comes great responsibility",
          user:
            '<a href="https://www.google.com/search?q=Peter+Parker" target="_blank" style="color:#0077c0;">Peter Parker</a>',
          parentId: 1,
          start: this.getDate(-24 * 4),
          duration: 4 * 24 * 60 * 60 * 1000,
          progress: 50,
          type: "milestone",
          collapsed: true,
          style: {
            base: {
              fill: "#1EBC61",
              stroke: "#0EAC51",
            },
            /*'tree-row-bar': {
              fill: '#1EBC61',
              stroke: '#0EAC51'
            },
            'tree-row-bar-polygon': {
              stroke: '#0EAC51'
            }*/
          },
        },
        {
          id: 3,
          label: "Courage is being scared to death, but saddling up anyway.",
          user:
            '<a href="https://www.google.com/search?q=John+Wayne" target="_blank" style="color:#0077c0;">John Wayne</a>',
          parentId: 2,
          start: this.getDate(-24 * 3),
          duration: 2 * 24 * 60 * 60 * 1000,
          progress: 100,
          type: "task",
        },
        {
          id: 4,
          label: "Put that toy AWAY!",
          user:
            '<a href="https://www.google.com/search?q=Clark+Kent" target="_blank" style="color:#0077c0;">Clark Kent</a>',
          start: this.getDate(-24 * 2),
          duration: 2 * 24 * 60 * 60 * 1000,
          progress: 50,
          type: "task",
          dependentOn: [3],
        },
      ],

      options: {
        // taskMapping: {
        //   progress: 'percent'
        // },
        maxRows: 1000,
        maxHeight: 600,
        title: {
          label: "Your project title as html (link or whatever...)",
          html: false,
        },
        row: {
          height: 24,
        },
        calendar: {
          hour: {
            display: true,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
        },
        taskList: {
          expander: {
            straight: false,
          },
          columns: [
            {
              id: 1,
              label: "ID",
              value: "id",
              width: 40,
            },
            {
              id: 2,
              label: "Oppgave",
              value: "label",
              width: 200,
              expander: true,
              html: true,
              events: {
                click({ data, column }) {
                  //this.currentTaskID = data.id;
                  //this.currentTaskID = data.id;
                  //alert('description clicked!\n' + this.currentTaskID + ' ' + data.id +  data.label);
                },
              },
            },
            {
              id: 3,
              label: "Ansvarlig",
              value: "user",
              width: 130,
              html: true,
            },
            {
              id: 4,
              label: "Start",
              value: "start",
              //value: task => dayjs(task.start).format('YYYY-MM-DD'),
              width: 78,
            },
            {
              id: 5,
              label: "Type",
              value: "type",
              width: 68,
            },
            {
              id: 6,
              label: "%",
              value: "progresjon",
              width: 35,
              style: {
                "task-list-header-label": {
                  "text-align": "center",
                  width: "100%",
                },
                "task-list-item-value-container": {
                  "text-align": "center",
                  width: "100%",
                },
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ny" : "Endre";
    },
  },

  watch: {
    myDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.keyProsjektID = this.$route.params.ProsjektID;
    this.GetGanttData(this.keyProsjektID);
  },
  methods: {
    UpdateDataList() {
      this.dataList = [];
      let i = 0;
      for (i = 0; i < this.tasks.length; i++) {
        const a = new Object();
        a.TaskID = this.tasks[i].id;
        a.Task = this.tasks[i].label;
        this.dataList.push(a);
      }
    },
    DoSave() {
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editedIndex > -1) {
        myUrl = DataSourceURL + "/Task/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/Task/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: this.editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK

              //Ordre kode felt
              this.editedItem.BrukerID = this.FindDescription(
                this.editedItem.BrukerID,
                this.BrukerItems
              );
              this.editedItem.TaskStatusID = this.FindDescription(
                this.editedItem.TaskStatusID,
                this.TaskStatusItems
              );
              this.editedItem.TaskTypeID = this.FindDescription(
                this.editedItem.TaskTypeID,
                this.TaskTypeItems
              );
              if (this.editedIndex > -1) {
                Object.assign(this.dataList[this.editedIndex], this.editedItem); //Oppdatering
              } else {
                this.editedItem.TaskID = res.data.ID;
                this.dataList.push(this.editedItem); //Ny
              }
              this.close();
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    save() {
      //Set key fields
      this.editedItem.ProsjektID = this.keyProsjektID;

      this.DoSave();
    },
    close() {
      this.myDialog = false;
      this.$nextTick(() => {
        this.initEditItem();
      });
    },
    initEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    GetTask(TaskID) {
      axios({
        method: "get",
        params: { TaskID: TaskID },
        url: DataSourceURL + "/Task/GetTask",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItem = res.data.DataList;
            this.myDialog = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Task/GetTask. See details: ${err}`
          );
        });
    },

    GetGanttData(keyProsjektID) {
      axios({
        method: "get",
        params: { ProsjektID: keyProsjektID },
        url: DataSourceURL + "/Gantt/GetGanttData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.tasks = res.data.DataList;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Task/GetTaskData. See details: ${err}`
          );
        });
    },
  },
  mounted() {
    this.$root.$on("gantt-elastic-ready", (ganttElasticInstance) => {
      this.ganttInstance = ganttElasticInstance;

      this.ganttInstance.$on("tasks-changed", (tasks) => {
        this.tasks = tasks;
      });
      this.ganttInstance.$on("options-changed", (options) => {
        this.options = options;
      });
      this.ganttInstance.$on("dynamic-style-changed", (style) => {
        this.dynamicStyle = style;
      });

      this.ganttInstance.$on("chart-task-mouseenter", ({ data, event }) => {
        console.log("task mouse enter", { data, event });
      });
      this.ganttInstance.$on("updated", () => {
        //console.log('gantt view was updated');
      });
      this.ganttInstance.$on("destroyed", () => {
        //console.log('gantt was destroyed');
      });
      this.ganttInstance.$on("times-timeZoom-updated", () => {
        console.log("time zoom changed");
      });
      this.ganttInstance.$on(
        "taskList-task-click",
        ({ event, data, column }) => {
          console.log("task list clicked! (task)", { data, column });
          this.currentTaskID = data.id;
          this.UpdateDataList();
          this.GetTask(this.currentTaskID);
        }
      );
    });
  },
};
</script>
